import { useState, useEffect } from 'react';
import SymbolSelect from 'components/ui/select/SymbolSelect';
import CautionItem from 'components/ui/CautionItem';
import FormInput from 'components/ui/input/FormInput';
import BaseButton from 'components/ui/button/BaseButton';
import { closeModal } from 'store/modules/components';
import RecentList from './RecentList';
import { addRequestWithdrawAPI } from 'api/wallet'
import { useSnackbar } from "notistack";
import { toNumberFormat } from 'utils/toNumberFormat';
import { setUserInfo } from 'store/modules/user';
import { getUserInfoAPI } from 'api/user';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { getLastWithdrawListAPI } from 'api/user'
import { getIsActivatePromotionAPI, getPromotionListAPI } from 'api/promotion'
import Dialog from '@mui/material/Dialog';
import useUserInfoUpdate from 'hooks/useUserInfoUpdate'

function removeParentheses(text) {
  const pattern = /\([^)]*\)/g;
  return text?.replace(pattern, '');
}

function PromoWithdrawalModal({ open, handleClose, data, loading }) {
  const { t } = useTranslation();
  const { amount, isFunction } = data;

  const confirm = () => {
    isFunction();
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="base-dialog-wrapper content-dialog-wrapper small"
    >
      <div className="base-dialog-inner">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title medium caution">{t("component.modal.promotionCaution.title")}</p>
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-body promotion">
          <div className="info">
            <ul className="info__inner">
              <li>{t("component.modal.promotionCaution.withdrawalInfo.1")}</li>
              <li>{t("component.modal.promotionCaution.withdrawalInfo.2", { amount: `$${toNumberFormat(amount, 2)}` })}</li>
            </ul>
            <div className="info__action">
              <BaseButton isLoading={loading} onClick={() => confirm()} color="danger" label="commonDesc.confirm" size="large" />
              <BaseButton onClick={() => handleClose()} color="gray" label="commonDesc.cancel" size="large" />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default function WithdrawLayout() {
  const { t } = useTranslation();
  const [select, setSelect] = useState(null);
  const [amount, setAmount] = useState(null);
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allBalanceList, setAllBalanceList] = useState([]);
  const [recentTokenList, setRecentTokenList] = useState([]);
  const [promotionActive, setPromotionActive] = useState(false);
  const [activePromoAmount, setActivePromoAmount] = useState(false);
  const [modalData, setModalData] = useState({});
  const [promoOpen, setPromoOpen] = useState(false);
  const [hasAddrBook, setHasAddrBook] = useState(false);
  const userInfo = useSelector(state => { return state?.user?.userInfo; });
  const { email: userEmail, balance, addrbook } = userInfo;
  const currentSymbol = useSelector(state => { return state?.user?.currentSymbol; });
  const selectData = { select, setSelect, selectList: allBalanceList, id: "coin-select-withdraw" };
  const fee = 3;
  const dispatch = useDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { updateUserBalance, sortUserBalance } = useUserInfoUpdate();

  useEffect(() => {
    if (select?.symbol) {
      setAddress(null)
      setAmount(null)
    }
    (select && select?.chain_name === "TRON" && addrbook) ? setHasAddrBook(true) : setHasAddrBook(false);
  }, [select?.symbol])

  useEffect(() => {
    updateUserBalance()
    getIsActivatePromotion()
    getLastWithdrawList();
  }, [])

  useEffect(() => {
    const list = sortUserBalance('balance');
    setAllBalanceList(list);

    if (currentSymbol && balance.length) {
      const currentSelect = list.find(item => item.symbol === currentSymbol.symbol);
      setSelect(currentSelect)
    }
  }, [balance])

  const getLastWithdrawList = async () => {
    try {
      const res = await getLastWithdrawListAPI(userEmail)
      if (res) {
        const filterList= res?.filter(currency => currency.symbol !== "USD");
        setRecentTokenList(filterList)
      }
    } catch (e) {
      console.log("getLastWithdrawListAPI e: ", e);
    }
  }

  const getIsActivatePromotion = async () => { // 활성화 유무
    try {
      const res = await getIsActivatePromotionAPI(userEmail);
      setPromotionActive(res);
      if (res) {
        const promoList = await getPromotionListAPI(userEmail);
        const activeSymbol = promoList.filter(item => item.status === "active")?.[0]?.symbol
        const activeBouns = balance.filter((item) => item.symbol === activeSymbol)?.[0]?.bonus;
        setActivePromoAmount(activeBouns)
      }
    }
    catch(e) {
      console.error("isActivatePromotion e: ", e)
    }
  }

  const addressInput = {
    id: 'withdraw-address',
    placeholder: 'component.modal.wallet.withdraw.address.placeholder',
    label: 'component.modal.wallet.withdraw.address.label',
    value: address,
    setValue: setAddress,
    hasAddrBook,
    fetchAddress: () => {
      setAddress(addrbook)
    }
  }
  const amountInput = {
    id: 'withdraw-amount',
    placeholder: 'component.modal.wallet.withdraw.amount.placeholder',
    label: 'component.modal.wallet.withdraw.amount.label',
    msg: `${t('commonDesc.balance')}: ${select?.balance ? toNumberFormat(select?.balance, 8) : "0.00000000"} ${select?.symbol}`,
    isBalance: true,
    value: amount,
    setValue: setAmount,
    isDisabled: !select?.balance,
    isMax: () => isMax()
  }

  const isMax = () => {
    if (select?.balance) {
      setAmount(select?.balance)
    }
  }

  const promoModalOpen = () => {
    setModalData({
      amount: activePromoAmount,
      isFunction: () => {
        addRequestWithdraw()
      }
    })
    setPromoOpen(true);
  };

  const promoModalClose = () => {
    setPromoOpen(false);
  };

  const promotionActiveCheck = () => {
    if (promotionActive) {
      promoModalOpen()
    } else {
      addRequestWithdraw()
    }
  }

  const addRequestWithdraw = async () => {
    if (loading) return;
    setLoading(true);
    if (!(amount-0 > fee-0)) {
      enqueueSnackbar({ msg: "component.modal.wallet.withdraw.feeInfo", variant: "error", action: () => {closeSnackbar()} })
      return;
    }

    await addRequestWithdrawAPI(userEmail, select?.symbol, address, amount)
    .then(async (res) => {
      if (res.err === 0) {
        const response = await getUserInfoAPI(userEmail);
        dispatch(setUserInfo(response));
        setAddress(null)
        setAmount(null)
        if (res.msg === "success") {
          enqueueSnackbar({ msg: "component.modal.wallet.withdraw.successInfo", variant: "success", action: () => {closeSnackbar()} })
          dispatch(closeModal());
        }
      } else {
        enqueueSnackbar({ msg: res.err, variant: "error", action: () => {closeSnackbar()} })
      }
      setLoading(false);
    })
    .catch(e => {
      setLoading(false);
      enqueueSnackbar({ msg: e.message, variant: "error", action: () => {closeSnackbar()} })
      console.log("addRequestWithdrawAPI e: ", e)
    })
  }

  return (
    <>
      <div className="withdraw-layout-wrapper">
        <div className="withdraw-layout-body">
          <div className="select-wrapper">
            <div className="select-coin">
              <p className="box-title">{t('component.modal.selectCoin')}</p>
              {select && <SymbolSelect {...selectData} notation="balance" />}
            </div>
            <RecentList list={recentTokenList} title="component.modal.wallet.withdraw.recent" setSelect={setSelect} balanceList={allBalanceList} />
            <div className="select-inner address">
              <FormInput labelSize="small" {...addressInput} />
            </div>
            <div className="select-inner network">
              <p className="box-title">{t('component.modal.network')}</p>
              <div className="selected-network">
                <p>{select?.chain_name === "ETHEREUM" ? "ERC20" : select?.chain_name}</p>
              </div>
            </div>
          </div>
          <CautionItem styled="row" desc="component.modal.wallet.withdraw.caution" />
        </div>
        <div className="withdraw-layout-result">
          <div className="withdraw-layout-result-inner">
            <FormInput labelSize="small" {...amountInput} />
            <div className="amount-wrapper">
              <p className="box-title">{t('component.modal.wallet.withdraw.amount.receiveAmount')}</p>
              <div className="amount-inner">
                <p className="amount-inner__number">{amount >= fee ? toNumberFormat(amount - fee, 2) : "0.00"} {removeParentheses(select?.symbol)}</p>
                <p className="amount-inner__fee">{t('component.modal.wallet.withdraw.fee')}: {fee} {select?.symbol}</p>
              </div>
            </div>
          </div>
          <p className="againWithdrawalInfo">{t('component.modal.wallet.withdraw.againWithdrawalInfo')}</p>
          {/* <BaseButton isLoading={loading} isDisabled={!address || !amount || loading} onClick={() => promotionActiveCheck()} size="large" color="primary" label="component.modal.wallet.withdraw.btn" /> */}
          <BaseButton isLoading={loading} isDisabled={true} onClick={() => promotionActiveCheck()} size="large" color="primary" label="component.modal.wallet.withdraw.btn" />
        </div>
      </div>
      <PromoWithdrawalModal open={promoOpen} handleClose={promoModalClose} data={modalData} loading={loading} />
    </>
  )
}